import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Id, toast } from "react-toastify";

// The InitialState For The Slice
const initialState: LocalUser = {
  user: undefined,
  email: undefined,
  loading: false,
  error: undefined
};

// The Authentication Redux Slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn: (
      state,
      newValue: PayloadAction<Omit<LocalUser, "loading" | "error">>
    ) => ({
      ...newValue.payload,
      loading: false,
      error: undefined
    }),
    startUpdate: state => ({ ...state, loading: true }),
    userError: (
      state,
      newValue: PayloadAction<[import("samuel-types").Err, Id | undefined]>
    ) => {
      if (newValue.payload[1]) {
        toast.update(newValue.payload[1], {
          render: newValue.payload[0].message,
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
      } else {
        // Toast
        toast.error(newValue.payload[0].message);
      }

      // Setting The State
      return {
        ...state,
        loading: false,
        error: newValue.payload[0]
      };
    },
    update: (state, newValue: PayloadAction<Partial<Drivn.User>>) => ({
      email: state.email,
      user: merge(state.user, newValue.payload),
      loading: false,
      error: undefined
    }),
    logOut: () => ({ ...initialState })
  }
});
// Exporting The Actions
export const { logIn, logOut, update, startUpdate, userError } =
  userSlice.actions;

// Exporting The Slice
export default userSlice;

const merge = <T>(value1: T, value2: Partial<T>): T => ({
  ...value1,
  ...value2
});
