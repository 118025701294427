import NoAuth from "pages/no-auth/no-auth";
import React from "react";

const FAQ = () => (
  <section className="bg-secBg">
    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-black">
        Frequently asked questions
      </h2>
      <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
        <div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              How does Drivn work?
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Drivn utilises a mobile application that employees can download to
              their smartphones. The app uses geofencing and machine learning to
              distinguish between personal and work-related transportation. This
              allows us to automatically provide accurate data on carbon
              emissions generated during employee commutes. Individual data will
              be available for the employee directly in the application, while
              the aggregated data will be displayed through a dashboard in the
              Drivn admin panel.
            </p>
          </div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              What are the benefits of using Drivn?
            </h3>
            <p className="text-gray-500 dark:text-gray-400 whitespace-pre-line">
              &emsp; Compliance: Drivn helps businesses comply with
              environmental regulations by providing real-time data on carbon
              emissions.
            </p>
            <p className="text-gray-500 dark:text-gray-400 whitespace-pre-line">
              &emsp;Employee Engagement: Our platform makes sustainable
              commuting a friendly competition among employees, fostering a
              culture of eco-friendly practices.
            </p>
            <p className="text-gray-500 dark:text-gray-400 whitespace-pre-line">
              &emsp; Data-Driven Insights: Drivn offers valuable insights into
              commuting patterns, allowing companies to make informed decisions
              for a greener future.
            </p>
          </div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              How is user privacy protected?
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              We take user privacy seriously. Drivn adheres to all relevant data
              protection laws within the EU, and users have control over their
              data. We provide clear information about the data we collect and
              how it is used, and users can opt-out or disable location tracking
              at any time. A data processor agreement in accordance with EU
              standards and The Danish Data Protection Agency is made between
              the client and Drivn upon company onboarding. Further information
              can be found within our Privacy Policy and&nbsp;
              <a
                href="https://docs.google.com/document/d/1m-s2S_aynmm6TF5MrMwnU1E2B7ky3ZvFFWmYKrbccM8/edit"
                rel="noreferrer"
                target="_blank"
                className="font-medium underline text-primary-600 dark:text-primary-500 hover:no-underline"
              >
                User Consent
              </a>
              .
            </p>
          </div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              How can my company get started with Drivn?
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Getting started is easy! Simply reach out to our team, and we'll
              guide you through the setup process. We'll help you customise the
              app for your organisation, provide training for your employees,
              and ensure a seamless integration into your sustainability
              initiatives.
            </p>
          </div>
        </div>
        <div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Can Drivn help with regulatory compliance?
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Absolutely. Drivn is designed to assist businesses in meeting
              regulatory requirements, especially in the context of GHG
              Protocol: scope 3 category 7 emissions (Employee Commuting).
            </p>
          </div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              How frequently does Drivn provide data insights?
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Drivn provides real-time insights, allowing businesses to access
              real time-data and information on commuting patterns and carbon
              emissions. This ensures that organisations can make timely
              decisions to reduce their environmental impact.
            </p>
          </div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              What is expected from the user in utilising the Drivn app?
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              To ensure a seamless experience and accurate data collection,
              users (employees) are encouraged to follow these guidelines:
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              Download and Register: Users should download the Drivn app from
              the provided link and register using their organisation's unique
              code.
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              Activate Location Services: To enable precise tracking, users must
              activate the “allow always” option of location services for the
              Drivn app. Drivn utilises location data responsibly and respects
              user privacy.
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              Hereafter, no further engagement is required from the user's
              perspective, however analytics and statistics is available for the
              user to view if wanted.
            </p>
          </div>
          <div className="mb-10">
            <h3 className="flex items-center mb-4 text-lg font-medium text-black">
              <svg
                className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              What types of transportation modes does Drivn cover?
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              &emsp;Drivn comprehensively covers various transportation modes,
              ensuring a complete analysis of your organisation's carbon
              footprint. The supported modes include:
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              &emsp;Car: Whether it's a company car or personal vehicle, Drivn
              tracks carbon emissions associated with car commuting.
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              &emsp;Train and Metro: For employees using public transportation,
              Drivn accurately records emissions related to train travel.
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              &emsp;Walking and Biking: Drivn recognizes sustainable modes of
              transportation, such as walking and biking, contributing to a
              holistic view of your environmental impact.
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              &emsp;<b>Bus:</b> Drivn also includes bus transportation in its tracking
              capabilities.
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              &emsp;Other transportation modes covered: <b>Ferry, Tram, Subway</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

// Exporting
export default <NoAuth component={FAQ} title="FAQ" />;
