import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

// Hooks
import { setDocumentTitle } from "@hooks/document-title";
import { useDarkMode } from "@hooks/dark-mode";

// Charts
import EmissionsByActivityType from "@components/charts/pie-chart";
import TotalCO2Emissions from "@components/charts/med-line-chart";
import BigEmissionComponent from "@components/charts/big-emissions-component";
import { parseAbsoluteToLocal } from "@internationalized/date";
import useAnalytics from "@hooks/analytics";

// UI Components
import { DateRangePicker } from "@nextui-org/react";
import { Slab } from "react-loading-indicators";
import useOrganization from "@hooks/organization";
import { setDates, useAppDispatch } from "@util";
import { DateValue } from "@react-types/datepicker";

const Analytics = () => {
  useEffect(() => {
    setDocumentTitle("Analytics");
  }, []);

  const analytics = useAnalytics();
  const org = useOrganization();
  const dark = useDarkMode();
  const dispatch = useAppDispatch();

  const [selectedRange, setSelectedRange] = useState(() => {
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 4);

    startDate.setHours(0, 1, 0, 0);
    currentDate.setHours(23, 59, 59, 999);

    return {
      start: parseAbsoluteToLocal(startDate.toISOString()),
      end: parseAbsoluteToLocal(currentDate.toISOString()),
    };
  });

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (en: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = en.target.value;
    setSelectedOption(selectedValue);

    const currentDate = new Date();
    let startDate;
    let dayOfWeek = currentDate.getDay();
    dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    switch (selectedValue) {
      case "week":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - dayOfWeek); 
        break;
      case "month":
        startDate = new Date(currentDate);
        startDate.setDate(1);
        break;
      case "year":
        startDate = new Date(currentDate);
        startDate.setMonth(0);
        startDate.setDate(1);
        break;
      default:
        startDate = new Date();
    }

    startDate.setHours(0, 0, 0, 0);
    currentDate.setHours(23, 59, 59, 999);

    const start = parseAbsoluteToLocal(startDate.toISOString());
    const end = parseAbsoluteToLocal(currentDate.toISOString());

    setSelectedRange({ start, end });
    dispatch(setDates({ start, end }));
    analytics.fetchRecent(start.toAbsoluteString(), end.toAbsoluteString());
  };

  const handleDateChange = (val: { start: DateValue | undefined, end: DateValue | undefined }) => {
    if (val.start && val.end) {
      const startDate = new Date(val.start.year, val.start.month - 1, val.start.day);
      const endDate = new Date(val.end.year, val.end.month - 1, val.end.day);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const start = parseAbsoluteToLocal(startDate.toISOString());
      const end = parseAbsoluteToLocal(endDate.toISOString());
      setSelectedRange({ start, end });

      dispatch(setDates({ start, end }));
      analytics.fetchRecent(start.toAbsoluteString(), end.toAbsoluteString());
    }
  };

  return (
    <>
      {analytics.loading ? (
        <div className="w-full min-h-[calc(100vh-110px)] mt-[110px] flex justify-center items-center">
          <Slab
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading Analytics..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      ) : (
        <div className="px-4 py-6 mt-[110px]">
          <div className="flex justify-between items-center mb-4">
            <div className="organization-name">
              <h1 className="text-2xl font-bold">{org.organization?.name}</h1>
            </div>
            <div className="ml-auto inline-flex items-center space-x-2 gap-2">
              <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="bg-white text-black border border-black px-2 py-1 rounded"
                style={{ width: "200px" }}
              >
                <option value="">Select Time Range</option>
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="year">This Year</option>
              </select>
              <DateRangePicker
                label="Stay duration"
                visibleMonths={2}
                value={selectedRange}
                onChange={handleDateChange}
                hideTimeZone={true}
                style={{
                  width: "200px", 
                }}
              />
            </div>
          </div>
          <BigEmissionComponent selectedOption={selectedOption} selectedRange={selectedRange} />
          <div className="mt-4 grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            <TotalCO2Emissions />
            <UserSignupsThisWeek />
            <EmissionsByActivityType />
          </div>
        </div>
      )}
    </>
  );
};

const UserSignupsThisWeek: React.FC = () => {
  const analytics = useAnalytics();

  return (
    <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
      <div className="flex items-center">
        <div className="shrink-0">
          <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
            {
              Object.values(analytics.recentAnalytics)
                .map(rec => rec.tripIds)
                .flat().length
            }
          </span>
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
            Amount of commutes
          </h3>
        </div>
      </div>
      <UserSignupsChart />
    </div>
  );
};

const UserSignupsChart: React.FC = () => {
  const { dark: isDarkTheme, primary } = useDarkMode();

  const analytics = useAnalytics();

  const backgroundBarColors = isDarkTheme
    ? Array(7).fill("#374151")
    : Array(7).fill("#E5E7EB");

  const options: ApexCharts.ApexOptions = {
    labels: Object.keys(analytics.recentAnalytics),
    chart: {
      foreColor: "#4B5563",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: true
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        color: primary
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",
        borderRadius: 3,
        colors: {
          backgroundBarColors,
          backgroundBarRadius: 3
        }
      }
    },
    xaxis: {
      floating: true,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    tooltip: {
      hideEmptySeries: false,
      shared: true,
      intersect: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif"
      }
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.8
        }
      }
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true 
    }
  };
  const series = [
    {
      name: "Commutes",
      data: Object.values(analytics.recentAnalytics).map(
        val => val.tripIds.length
      )
    }
  ];

  return <Chart height={305} options={options} series={series} type="bar" />;
};

export default Analytics;
