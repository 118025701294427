import React from "react";

import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";

// Helpers
import ScrollToTop from "./util/hooks/scroll-to-top";
import { NextUIProvider } from "@nextui-org/react";
// Redux
import { Provider } from "react-redux";
import store from "./util/redux/store";

// Styles
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";

// Pages
import HomePage from "./pages/main";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <NextUIProvider>
      <BrowserRouter>
        <ScrollToTop />
        <HomePage />
      </BrowserRouter>
    </NextUIProvider>
  </Provider>
);
