import React, { useEffect } from "react";

// Helpers
import { Slab } from "react-loading-indicators";

// Hooks
import { setDocumentTitle } from "@hooks/document-title";
import { useDarkMode } from "@hooks/dark-mode";
import useOffices from "@hooks/offices";

// Subviews
import OfficeDrawer from "./subviews/office-drawer";
import OfficeCell from "./subviews/office-cell";
import DeleteModal from "@components/delete-modal";
import { updateTempOffice, useAppDispatch } from "@util";

/* eslint-disable no-console */
export default function Offices() {
  // The User Offices
  const off = useOffices();

  // App Dispatch
  const dispatch = useAppDispatch();

  const handleNext = () => {
    const newOffset = (off.currentPage + 1) * off.limit;
    off.setOffset(newOffset);
    off.setCurrentPage(off.currentPage + 1);
  };

  const handlePrev = () => {
    const newCurrentPage = Math.max(0, off.currentPage - 1);
    off.setCurrentPage(newCurrentPage);
    const newOffset = newCurrentPage * off.limit;
    off.setOffset(newOffset);
    console.log("offset: ", newOffset);
  }; 
  
  // The Current Dark Mode
  const dark = useDarkMode();

  // Called Before The View Loads
  useEffect(() => {
    // Setting The Document Title
    setDocumentTitle("Offices");
  }, []);

  // Flatten offices array
  const allOffices = off.offices.flatMap(set => set.offices);

  return (
    <div className="mt-[110px] overflow-x-hidden">
      {off.loading && off.total < 0? (
        <div className="w-full min-h-[calc(100vh-110px)] flex justify-center items-center">
          <Slab
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading Offices..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      ) : (
        <>
          {/* Start block */}
          <section className="bg-bg py-3 relative sm:py-5 antialiased min-h-[calc(100vh-110px)]">
            <div className="mx-auto max-w-screen-2xl px-4 lg:px-12">
              <div className="bg-secBg relative shadow-md sm:rounded-lg">
                <div className="flex flex-col md:flex-row md:items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                  <div className="w-full md:w-1/2">
                    <form className="flex items-center">
                      <h1 className="font-semibold text-2xl ml-5">Offices</h1>
                    </form>
                  </div>
                  <div className="flex flex-row items-center justify-between md:justify-end md:space-x-3 flex-shrink-0">
                    <div className="flex items-center space-x-3">
                      <button
                        type="button"
                        onClick={() =>
                          dispatch(
                            updateTempOffice({
                              name: "",
                              estimatedEmployeeCount: 0,
                              city: "",
                              zip: "",
                              streetNameAndNumber: "",
                              state: "",
                              orgId: "",
                              color: "",
                              lat: 0,
                              lng: 0,
                              radius: 150,
                              removed: false
                            })
                          )
                        }
                        className="flex items-center transition-all justify-center text-black bg-bg hover:bg-main hover:text-bg font-medium rounded-lg text-sm px-4 py-2 border border-[rgba(255,255,255,0.5)] hover:border-none"
                      >
                        <svg
                          className="h-3.5 w-3.5 mr-2 flex-shrink-0 hidden md:inline"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          />
                        </svg>
                        Add Office
                      </button>
                    </div>
                    <div className="flex items-center space-x-1">
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto mx-4 space-y-4">
                  {allOffices.slice(off.offset, off.offset + off.limit).map(office => (
                    <OfficeCell key={office.id} office={office} removeOffice={off.removeOffice}/>
                  ))}
                  <nav
                    className="flex flex-col items-center justify-center space-y-3 py-5"
                    aria-label="Table navigation"
                  >
                    <div className="flex items-center">
                      <div className="text-xs font-normal text-gray-500 dark:text-gray-400">
                        Showing&ensp;
                        <b className="font-semibold text-black">
                          {off.total <= 0 ? 0 : off.offset + 1}
                          &ensp;
                        </b>
                        to&ensp;
                        <b className="font-semibold text-black">
                          {Math.min((off.offset + off.limit), off.total)}
                          &ensp;
                        </b>
                        of&ensp;
                        <b className="font-semibold text-black">
                          {off.total}&ensp;
                        </b>
                        offices
                      </div>
                    </div>
                    <ul className="inline-flex items-stretch -space-x-px">
                      <li>
                        <button
                          onClick={() => {
                            handlePrev();
                            console.log("offset: ", off.offset);
                          }}                          
                          disabled={off.offset === 0}
                          className="flex opacity-50 enabled:opacity-100 text-sm w-20 items-center justify-center h-full py-1.5 px-3 ml-0 text-black bg-bg rounded-l-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg transition-all enabled:hover:border-main dark:border-gray-700"
                        >
                          Prev
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            if (off.offset + off.limit < off.total) {
                              handleNext();
                              console.log("offset: ", off.offset);
                            }
                          }}                          
                          disabled={off.offset + off.limit >= off.total}
                          className="flex opacity-50 enabled:opacity-100 text-sm w-20 items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-bg rounded-r-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg transition-all enabled:hover:border-main dark:border-gray-700"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </section>

          <OfficeDrawer />
          <DeleteModal />
        </>
      )}
    </div>
  );
}
