import React, { useEffect, useState } from "react";

// Sub Components
import Navbar from "../../components/navigation/navbar";
import Footer from "../../components/navigation/footer";

// Helpers
import { setDocumentTitle } from "@hooks/document-title";

export default function NoAuth(props: Drivn.NoAuthProps) {
  // The State Of The Nav Menu
  const [menu, setMenu] = useState(false);

  // The React Component
  const { component: Component } = props;

  useEffect(() => {
    setDocumentTitle(props.title);
  });

  return (
    <section className={`${menu ? "overflow-hidden" : ""} h-screen`}>
      <Navbar open={menu} toggleOpen={setMenu} />
      <Component />
      <Footer />
    </section>
  );
}
