import React, { useState } from "react";
import BigEmissionsChart from "./big-emissions-chart";
import useAnalytics from "@hooks/analytics";
import { DateValue } from "@react-types/datepicker";
import "./big-emissions-component.tsx.css";

const BigEmissionComponent: React.FC<{
  selectedOption: string;
  selectedRange: { start: DateValue; end: DateValue };
}> = ({ selectedOption, selectedRange }) => {
  const analytics = useAnalytics();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const getEmissonDiff = () => {
    const endEmissions = Number(
      analytics.recentAnalytics[analytics.formattedEnd]?.totalEmissions ?? 0
    );
    const startEmissions = Number(
      analytics.recentAnalytics[analytics.formattedStart]?.totalEmissions ?? 0
    );
    return endEmissions - startEmissions;
  };

  const emissionDiff = getEmissonDiff();
  const formattedEmissionDiff = emissionDiff > 0 ? `+${emissionDiff.toFixed(2)}` : `${emissionDiff.toFixed(2)}`;
  const tooltipText = emissionDiff > 0 
    ? `You spent  ${formattedEmissionDiff} kg compared to the last time period ` 
    : `You saved  ${formattedEmissionDiff} kg this time period`;

  return (
    <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
      <div className="mb-4 flex items-center justify-between">
        <div className="shrink-0">
          <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
            {Object.values(analytics.recentAnalytics).length === 0
              ? 0
              : Object.values(analytics.recentAnalytics)
                .map(ana => ana.totalEmissions)
                .reduce((ana1, ana2) => ana1 + ana2)
                .toFixed(2)}{" "}
          kg
          </span>
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
            CO₂ Emissions
          </h3>
        </div>
        <div className="flex items-center">
          <div
            className={`flex items-center justify-end text-base font-bold ${
              emissionDiff > 0
                ? "text-red-600 dark:text-red-400"
                : emissionDiff < 0
                ? "text-green-600 dark:text-green-400"
                : "text-gray-600 dark:text-gray-400"
            }`}
            style={{ marginRight: "15px" }}
          >
            {formattedEmissionDiff}kg
            <span
              className="tooltip"
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              ?
              {tooltipVisible && (
                <span className="tooltip-text">{tooltipText}</span>
              )}
            </span>
          </div>
        </div>
      </div>
      <BigEmissionsChart
        selectedOption={selectedOption}
        selectedRange={selectedRange}
      />
    </div>
  );
};

export default BigEmissionComponent;
