import React from "react";

// React Components
import NoAuth from "../no-auth";

// Helpers
import Overview from "./overview";
import Pricing from "./pricing";
import Main from "./main";
import Features from "./features";
import Clients from "./clients";

const Home = () => (
  <main>
    <Main />
    <Overview />
    <Features />
    <Pricing />
    <Clients />
  </main>
);

export default <NoAuth component={Home} title="Home" />;
