import React from "react";
import Chart from "react-apexcharts";
import { useDarkMode } from "@hooks/dark-mode";
import useAnalytics from "@hooks/analytics";
import { DateValue } from "@react-types/datepicker";
import { saveAs } from "file-saver";
import { formatDate, formatDateString } from "../../util/hooks/dateUtils";

const BigEmissionsChart: React.FC<{ selectedOption: string, selectedRange: { start: DateValue; end: DateValue } }> = ({ selectedOption, selectedRange }) => {
  const isDarkTheme = useDarkMode();

  const borderColor = isDarkTheme.dark ? "#374151" : "#F3F4F6";
  const labelColor = isDarkTheme.dark ? "#93ACAF" : "#6B7280";
  const opacityFrom = isDarkTheme.dark ? 0 : 0.45;
  const opacityTo = isDarkTheme.dark ? 0.15 : 0;
  
  const analytics = useAnalytics();

  const generateCustomCSV = () => {
    const formattedStartDate = formatDate(selectedRange.start);
    const formattedEndDate = formatDate(selectedRange.end);
    const filename = `emissions_${formattedStartDate}_${formattedEndDate}.csv`;

    const startDate = new Date(
      selectedRange.start.year,
      selectedRange.start.month - 1,
      selectedRange.start.day
    );
    const endDate = new Date(
      selectedRange.end.year,
      selectedRange.end.month - 1,
      selectedRange.end.day
    );

    // Calculate the number of months in the selected range
    const monthsDifference =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());

    if (monthsDifference >= 1) {
      // Generate CSV with months as columns for "this year" or multiple months
      const daysInMonth = 31;
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      // Filter months to include only those in the selected date range
      const monthsInRangeSet = new Set<number>();
      let tempDate = new Date(startDate);
      while (tempDate <= endDate) {
        monthsInRangeSet.add(tempDate.getMonth()); 
        tempDate.setMonth(tempDate.getMonth() + 1);
      }

      const monthsArray = Array.from(monthsInRangeSet).sort((one, two) => one - two);
      const monthsInRange = monthsArray.map(monthIndex => months[monthIndex]);

      // Initialize csvData with header row
      const csvData = [];
      const headerRow = ["Day", ...monthsInRange];
      csvData.push(headerRow);

      // Initialize emissionsData as a 2D array
      const emissionsData: any[][] = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const row = new Array(monthsInRange.length + 1).fill("0.00");
        row[0] = day.toString();
        emissionsData.push(row);
      }

      // Initialize totals per month
      const totals = new Array(monthsInRange.length).fill(0);

      // Populate emissionsData
      Object.entries(analytics.recentAnalytics).forEach(([date, ana]) => {
        const parsedDate = new Date(date);
        if (parsedDate >= startDate && parsedDate <= endDate) {
          const day = parsedDate.getDate();
          const month = parsedDate.getMonth();

          const monthIndex = monthsArray.indexOf(month);
          if (monthIndex !== -1) {
           
            const emission = Number(ana.totalEmissions ?? 0);

            emissionsData[day - 1][monthIndex + 1] = emission.toFixed(2);

            totals[monthIndex] += emission;
          }
        }
      });

      // Add emissionsData to csvData
      emissionsData.forEach(row => {
        // Filter out days that are not in the selected date range
        const day = Number(row[0]);
        const date = new Date(startDate.getFullYear(), startDate.getMonth(), day);
        if (date >= startDate && date <= endDate) {
          csvData.push(row);
        }
      });

      // Add total row
      const totalRow = ["Total", ...totals.map(total => total.toFixed(2))];
      csvData.push(totalRow);

      // Convert csvData to CSV content
      let csvContent = csvData.map(row => row.join(";")).join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, filename);

    } else {
      // Generate CSV with "Day" and "Emission" columns for "this month" or "this week"
      const csvData = [];
      const headerRow = ["Date", "Emission"];
      csvData.push(headerRow);

      let totalEmission = 0;

      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dateLabel = formatDateString(currentDate);
        const dateString = currentDate.toISOString().split("T")[0]; 
        const emissionEntry = analytics.recentAnalytics[dateString];
        const emission = emissionEntry ? Number(emissionEntry.totalEmissions ?? 0) : 0;

        totalEmission += emission;

        const row = [dateLabel, emission.toFixed(2)];
        csvData.push(row);

        // Move to next date
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Add total row
      csvData.push(["Total", totalEmission.toFixed(2)]);
      let csvContent = csvData.map(row => row.join(";")).join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, filename);
    }
  };
  
  const options: ApexCharts.ApexOptions = {
    stroke: {
      curve: "smooth"
    },
    chart: {
      type: "area",
      fontFamily: "Inter, sans-serif",
      foreColor: labelColor,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: false,
          customIcons: [
            {
              icon: "<div style='margin: 1.5px;'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#6e8192' d='M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z'/></svg><div>",
              index: -1,
              title: "Download Custom CSV",
              class: "csv-download-button",
              click: () => {
                generateCustomCSV();
              }
            }
          ]
        }
      },
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom,
        opacityTo,
        type: "vertical"
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      hideEmptySeries: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif"
      }
    },
    grid: {
      show: true,
      borderColor: borderColor,
      strokeDashArray: 1,
      padding: {
        left: 35,
        bottom: 15
      }
    },
    markers: {
      size: 5,
      strokeColors: "#ffffff",
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    xaxis: {
      type: "datetime",
      categories: Object.keys(analytics.recentAnalytics)
        .sort((rec1, rec2) => new Date(rec1).getTime() - new Date(rec2).getTime())
        .map(val => new Date(val).getTime()),
      labels: {
        style: {
          colors: [labelColor],
          fontSize: "14px",
          fontWeight: 500
        }
      },
      axisBorder: {
        color: borderColor
      },
      axisTicks: {
        color: borderColor
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 10
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: [labelColor],
          fontSize: "14px",
          fontWeight: 500
        },
        formatter: value => `${value.toFixed(2)}kg`
      }
    },
    legend: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [labelColor]
      },
      itemMargin: {
        horizontal: 10
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          xaxis: {
            labels: {
              show: false
            }
          }
        }
      }
    ]
  };
  
  const series: ApexAxisChartSeries = [
    {
      name: "Emissions",
      data: Object.entries(analytics.recentAnalytics).map(([date, ana]) => ({
        x: new Date(date).getTime(),
        y: Number(ana.totalEmissions ?? 0),
      })),
      color: isDarkTheme.primary
    }
  ];
  

  return <Chart height={420} options={options} series={series} type="area" />;
};

export default BigEmissionsChart;
