// Redux
// eslint-disable-next-line no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

// Slices
import officeSlice from "./slices/office-slice";
import userSlice from "./slices/user-slice";
import departmentSlice from "./slices/department-slice";
import orgSlice from "./slices/organization-slice";
import employeeSlice from "./slices/employee-slice";
import analyticsSlice from "./slices/analytics-slice";

// The Redux Store
const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    offices: officeSlice.reducer,
    departments: departmentSlice.reducer,
    organization: orgSlice.reducer,
    employees: employeeSlice.reducer,
    analytics: analyticsSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// Exporting The Root State
export type RootState = ReturnType<typeof store.getState>;

// The Dispatch State
export type AppDispatch = typeof store.dispatch;

// Exporting The Store
export default store;

// Custom App Dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;

// Custom App Selector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
