export const Routes = {
  NotLoggedIn: { path: "/", protected: false },
  FAQ: { path: "/faq", protected: false },
  Dashboard: {
    path: "/dashboard",
    protected: true,
    Analytics: {
      absolutePath: "/dashboard",
      path: ""
    },
    Offices: {
      absolutePath: "/dashboard/offices",
      path: "offices"
    },
    Departments: {
      absolutePath: "/dashboard/departments",
      path: "departments"
    },
    Employees: {
      absolutePath: "/dashboard/employees",
      path: "employees"
    },
    Settings: {
      absolutePath: "/dashboard/settings",
      path: "settings",
      Billing: {
        PaymentInfo: {
          absolutePath: "/dashboard/settings/billing/info",
          path: "billing/info"
        },
        Invoices: {
          absolutePath: "/dashboard/settings/billing/invoices",
          path: "billing/invoices"
        }
      },
      Organization: {
        absolutePath: "/dashboard/settings/organization",
        path: "organization"
      }
    }
  },
  Signin: { path: "/sign-in", protected: false },
  Onboarding: { path: "/onboarding", protected: false },
  Pricing: { path: "/pricing", protected: false },
  ForgotPassword: { path: "/forgot-password", protected: false },
  ResetPassword: { path: "/reset-password", protected: false },
  NotFound: { path: "/404", protected: false },
  TermsAndConditions: { path: "/terms-and-conditions", protected: false },
  PrivacyPolicy: { path: "/privacy-policy", protected: false },
  Contact: { path: "/contact", protected: false },
  ContactSales: { path: "contact-sales", protected: false }
};

export { Link, useNavigate } from "react-router-dom";
