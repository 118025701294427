import React from "react";

// Links
import { MacAndiOS } from "@assets";
import { Link } from "react-scroll";

export default function Overview() {
  return (
    <section
      id="overview"
      className="bg-secBg lg:h-screen flex justify-center items-center border-b border-gray-600 snap-start pt-32"
    >
      <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img className="lg:scale-125 xl:scale-150 lg:ml-10" src={MacAndiOS} alt="dashboard" />
        <div className="mt-4 md:mt-0">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-black">
            Empower Your Commute Decisions with Insights That Matter
          </h2>
          <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Drivn's dashboard turns transportation data into actionable
            insights, helping you track and reduce your company’s transportation
            emissions effortlessly. Harness the power of data to advance your
            environmental objectives and cultivate eco-friendly commuting habits
            in your company.
          </p>
          <Link
            to="pricing"
            spy={true}
            smooth={true}
            duration={500}
            className="inline-flex cursor-pointer items-center text-white bg-main hover:bg-white transition-all hover:text-main font-medium rounded-full text-sm px-5 py-2.5 text-center"
          >
            Get started
            <svg
              className="ml-2 -mr-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
}
