// Firebase Imports
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// The Firebase Web Configuration
const config = {
  apiKey: process.env.REACT_APP_FRONTEND_API_KEY,
  authDomain: process.env.REACT_APP_FRONTEND_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FRONTEND_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FRONTEND_APP_ID,
};

// The Firebase Application
const app = initializeApp(config);

// The Authentication Interface For Firebase
export const auth = getAuth(app);

// Exporting Packages
export {
  signOut as firebaseSignOut,
  signInWithCustomToken as firebaseSignIn,
  onAuthStateChanged as onAuthChange,
  getIdToken as token,
  type User as FirebaseUser
} from "firebase/auth";