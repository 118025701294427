import React, { useEffect } from "react";

// Hooks
import { setDocumentTitle } from "@hooks/document-title";

// Routing
import { Outlet } from "react-router";

// Helpers
import SettingsModal from "./subviews/settings-modal";

const Settings = () => {
  // Called Before The View Loads
  useEffect(() => {
    // Setting The Document Title
    setDocumentTitle("Settings");
  }, []);

  return (
    <section className="flex">
      <SettingsModal />
      <div className="w-full sm:w-[calc(100vw-16rem)] sm:ml-64 mt-[110px] min-h-[calc(100vh-110px)] bg-bg">
        <Outlet />
      </div>
    </section>
  );
};

export default Settings;
