import React from "react";
import NoAuth from "./no-auth";
import { InlineWidget } from "react-calendly";
import { Tesla } from "@assets";

const ContactSales = () => (
  <div className="min-h-[calc(100vh-110px)]">
    <InlineWidget
      url="https://calendly.com/herche_drivn/intro-meeting"
      styles={{
        height: "100vh",
        backgroundImage: `${Tesla}`
      }}
    />
  </div>
);


export default <NoAuth title="Contact Sales" component={ContactSales} />;