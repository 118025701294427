import React from "react";

import { Breadcrumb, Card } from "flowbite-react";
import type { FC } from "react";
import { HiHome } from "react-icons/hi";

const Billing: FC = function () {
  return (
    <>
      <div className="mb-6 grid grid-cols-1 gap-y-6 px-4 pt-6 dark:border-gray-700 xl:grid-cols-2 xl:gap-4">
        <div className="col-span-full">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href="/">
              <div className="flex items-center gap-x-3">
                <HiHome className="text-xl" />
                <span className="">Home</span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/e-commerce/products">
              Settings
            </Breadcrumb.Item>
            <Breadcrumb.Item>Billing</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="text-xl font-semibold text-black sm:text-2xl">
            Billing
          </h1>
        </div>
        <IntroCard />
      </div>
      <div className="grid grid-cols-1 gap-y-6 px-4">
        <GeneralInfoCard />
        <CardDetailsCard />
      </div>
    </>
  );
};

const IntroCard: FC = function () {
  return (
    <Card className="!bg-secBg">
      <h1 className="flex items-center text-2xl font-bold">
        <img alt="" src="../../images/logo.svg" className="mr-4 h-11" />
        <span>Drivn</span>
      </h1>
      <p className="text-base font-normal text-gray-500 dark:text-gray-400">
        Switch your subscription to a different type, such as a monthly plan,
        annual plan, or student plan. And see a list of subscription plans that
        Drivn offers.
      </p>
      <p className="text-sm font-semibold text-black">
        Next payment of $36 (yearly) occurs on August 13, 2024.
      </p>
      <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
        <div>
          <a
            href="/"
            className="inline-flex w-full items-center justify-center rounded-lg border hover:text-bg border-gray-500 px-5 py-2.5 text-center text-sm font-medium text-black hover:bg-main transition-all focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:w-auto"
          >
            <svg
              className="-ml-1 mr-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clipRule="evenodd"
              />
            </svg>
            Change Plan
          </a>
        </div>
        <div>
          <a
            href="/"
            className="inline-flex w-full items-center justify-center rounded-lg border border-gray-300 bg-white px-5 py-2.5 text-center text-sm font-medium text-black focus:ring-4 focus:ring-primary-300 dark:border-gray-600 dark:bg-bg dark:text-gray-400 hover:bg-red-400 dark:focus:ring-gray-700 sm:w-auto"
          >
            Cancel Subscription
          </a>
        </div>
      </div>
    </Card>
  );
};

const GeneralInfoCard: FC = function () {
  return (
    <Card className="!bg-secBg">
      <h3 className="mb-4 text-xl font-bold">Billing Information</h3>
      <form>
        <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="col-span-1 grid grid-cols-1 gap-y-3">
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                First Name
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="John"
                required={false}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                Organization
              </label>
              <input
                type="text"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="Company Name"
                required={false}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                Department
              </label>
              <input
                type="text"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="Development"
                required={false}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                City
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="eg., Copenhagen"
                required={false}
              />
            </div>
          </div>
          <div className="col-span-1 grid grid-cols-1 gap-y-3">
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                Last name
              </label>
              <input
                type="text"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="Doe"
                required={false}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                Role
              </label>
              <input
                type="text"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="Chief Sustainaility Officer"
                required={false}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                Country
              </label>
              <input
                type="text"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="Denmark"
                required={false}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <label className="block mb-2 text-sm font-medium text-black">
                Email
              </label>
              <input
                type="text"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="example@company.com"
                required={false}
              />
            </div>
          </div>
        </div>
        <div className="col-span-6 ml-auto">
          <button className="px-3 py-2 bg-secBg border border-gray-500 rounded-lg hover:bg-main transition-all hover:text-bg">
            Save Changes
          </button>
        </div>
      </form>
    </Card>
  );
};

const CardDetailsCard: FC = function () {
  return (
    <Card className="!bg-secBg mb-10">
      <h3 className="mb-4 text-xl font-bold">Card Details</h3>
      <form>
        <div className="mb-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="grid grid-cols-1 gap-y-2">
            <label className="block mb-2 text-sm font-medium text-black">
              (Full name as displayed on card)*
            </label>
            <input
              type="password"
              className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
              placeholder="Enter your name"
              required={false}
            />
          </div>
          <div className="grid grid-cols-1 gap-y-2">
            <label className="block mb-2 text-sm font-medium text-black">
              Card Number *
            </label>
            <input
              type="password"
              className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
              placeholder="xxxx-xxxx-xxxx-xxxx"
              required={false}
            />
          </div>
          <div className="grid grid-cols-1 gap-y-2">
            <label className="block mb-2 text-sm font-medium text-black">
              CVC
            </label>
            <input
              type="password"
              className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
              placeholder="•••"
              required={false}
            />
          </div>
          <div className="grid grid-cols-1 gap-y-2">
            <label className="block mb-2 text-sm font-medium text-black">
              Postal / ZIP code (optional)
            </label>
            <input
              type="text"
              className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
              placeholder="e.g. 12345"
              required={false}
            />
          </div>
        </div>
        <div className="col-span-6 ml-auto">
          <button className="px-3 py-2 bg-secBg border border-gray-500 rounded-lg hover:bg-main transition-all hover:text-bg">
            Save Changes
          </button>
        </div>
      </form>
    </Card>
  );
};

export default Billing;
