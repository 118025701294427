import { useEffect, useState } from "react";

// Helpers
import { useApi, useOrgCode } from "@hooks/api";
import {
  useAppDispatch,
  useAppSelector,
  Err,
  organizationError,
  updateOrg,
  setOrg,
  startOrgUpdate
} from "@util";
import { toast } from "react-toastify";

/**
 * Handle Organization State
 */
const useOrganization = () => {
  // The Current Organization
  const { organization, loading, error } = useAppSelector(
    state => state.organization
  );

  // The Temporary Fetched Organization
  const [fetchedOrganization, setOrganization] = useState<
    Drivn.Organization | undefined
  >();

  // App Dispatch
  const dispatch = useAppDispatch();

  // The API
  const api = useApi();

  // Update A Organization
  const updateOrganization = (newValues: Partial<Drivn.Organization>) => {
    // The Values To Update
    const toUpdate = newValues;

    // Setting The Toast
    const id = toast.loading("Updating Organization...");

    // Set Loading
    dispatch(startOrgUpdate());

    // Making The API Request
    api
      .requestWithJSON<Drivn.Organization>(
        `/organization/${useOrgCode}`,
        true,
        "PUT",
        toUpdate
      )
      .then(([, res]) => {
        // Handling Errors
        if (res instanceof Err) {
          dispatch(organizationError([res, id]));
          return;
        }

        // Updating The Toast
        toast.update(id, {
          render: "Updated organization successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000
        });

        // Setting The Redux Organization
        dispatch(updateOrg(res));
      })
      .catch(err => api.handleErrors(err, organizationError, id));
  };

  // Checking The Auth State
  useEffect(() => {
    // If The Organization Is Not Logged In Or The Organization Was Already Fetched, End Here
    if (fetchedOrganization !== undefined || loading || error) {
      return;
    }

    // Setting Loading
    dispatch(startOrgUpdate());

    // Fetching The Organization
    api
      .requestWithJSON<Drivn.Organization>(`/organization/${useOrgCode}`, true)
      .then(([, res]) => {
        // TODO -  Handle Errors Here
        if (res instanceof Err) {
          dispatch(organizationError([res, undefined]));
          return;
        }

        // Setting The Organization
        dispatch(setOrg({ organization: res }));
        setOrganization(res);
      })
      .catch(err => api.handleErrors(err, organizationError));
  }, [api, dispatch, error, fetchedOrganization, loading]);

  return {
    organization: organization ?? fetchedOrganization,
    loading,
    updateOrganization,
    error
  };
};

// Exporting The Organization
export default useOrganization;
