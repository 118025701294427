import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";

// Mapbox Styles
import "mapbox-gl/dist/mapbox-gl.css";
import { useDarkMode } from "@hooks/dark-mode";

// Define props for the MapComponent
type MapComponentProps = {
  location: OfficeForCreate;
  // Add a boolean if the map should be editable
  editable: boolean;

  onLocationChanged?: (location: OfficeForCreate) => void;
};

const MapComponent: React.FC<MapComponentProps> = ({
  location,
  editable,
  onLocationChanged
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);

  const [circleRadius, setCircleRadius] = useState(location.radius ?? 50);
  const map = useRef<mapboxgl.Map | null>(null);
  const circleRadiusRef = useRef(circleRadius);

  // Function to handle location changes
  const handleLocationChange = (location: OfficeForCreate) => {
    if (onLocationChanged) {
      onLocationChanged(location);
    }
  };

  // This state will keep track of the marker's position
  const [fineLocation, setFineLocation] = useState(location);

  const dark = useDarkMode();

  const updateFineLocation = (
    newLat: number,
    newLng: number,
    newRadius: number
  ) => {
    // Create a new Location object with the updated values
    const updatedLocation = {
      ...fineLocation,
      lat: newLat,
      lng: newLng,
      radius: newRadius
    };

    // Use the setFineLocation to update the state with the new object
    setFineLocation(updatedLocation);
    handleLocationChange(updatedLocation);
  };

  // Function to calculate radius in pixels from meters at a given latitude and zoom level.
  const getRadiusInPixels = (
    meters: number,
    latitude: number,
    zoom: number
  ) => {
    const pixelsPerMeter = getPixelsPerMeterAtLatitude(latitude, zoom);
    return meters * pixelsPerMeter;
  };

  // Function to get pixels per meter at a given latitude and zoom level.
  const getPixelsPerMeterAtLatitude = (latitude: number, zoom: number) => {
    const earthCircumference = 40075017;
    const latitudeRadians = latitude * (Math.PI / 180);
    const metersPerPx =
      (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoom + 8);
    return 1 / metersPerPx;
  };

  // Update the ref whenever the circleRadius state changes
  useEffect(() => {
    circleRadiusRef.current = circleRadius;
  }, [circleRadius]);

  useEffect(() => {
    if (map.current) {
      return;
    }

    // Setting The Access Token
    mapboxgl.accessToken =
      "pk.eyJ1Ijoic2FtdWVsZHJpdm4zIiwiYSI6ImNsbmRkaGVtdDAzb2Myam1uc3c3b3Nqb3UifQ.5J24NqomW_ZmXOMeVBXp7g";

    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: dark.dark
        ? "mapbox://styles/mapbox/dark-v11"
        : "mapbox://styles/mapbox/streets-v11",
      center: [location.lng, location.lat],
      zoom: editable ? 15 : 12
    });

    const onMapLoad = () => {
      if (!map.current) {
        return;
      }

      map.current.addSource("circle-source", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [location.lng, location.lat]
          },
          properties: {}
        }
      });

      map.current.addLayer({
        id: "circle-radius",
        type: "circle",
        source: "circle-source",
        paint: {
          "circle-radius": getRadiusInPixels(
            circleRadius,
            location.lat,
            map.current.getZoom()
          ),
          "circle-color": dark.dark ? "#80abf9" : "#1673ff",
          "circle-opacity": 0.5
        }
      });

      // Create a draggable marker and set its initial position
      let tempMarker = new mapboxgl.Marker({
        color: "red",
        draggable: editable
      })
        .setLngLat([location.lng, location.lat])
        .addTo(map.current);

      // Event listener for when the marker is dragged
      tempMarker.on("dragend", () => {
        if (!map.current) {
          return;
        }
        const lngLat = tempMarker.getLngLat();

        updateFineLocation(lngLat.lat, lngLat.lng, circleRadiusRef.current);

        // Also update the circle's center if needed
        const circleSource = map.current.getSource(
          "circle-source"
        ) as mapboxgl.GeoJSONSource;
        if (circleSource) {
          circleSource.setData({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [lngLat.lng, lngLat.lat]
            },
            properties: {}
          });
        }
      });

      if (editable) {
        // Add navigation control (the +/- zoom buttons)
        map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
      }
    };

    const onZoom = () => {
      if (map.current && map.current.isStyleLoaded()) {
        const currentRadius = circleRadiusRef.current;
        const newRadiusPixels = getRadiusInPixels(
          currentRadius,
          location.lat,
          map.current.getZoom()
        );
        map.current.setPaintProperty(
          "circle-radius",
          "circle-radius",
          newRadiusPixels
        );
      }
    };

    map.current.on("load", onMapLoad);
    map.current.on("zoom", onZoom);

    map.current.scrollZoom.disable();

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
    // eslint-disable-next-line
  }, []);

  const handleRadiusChange = (newRadius: number) => {
    setCircleRadius(newRadius);
    if (map.current && map.current.isStyleLoaded()) {
      const newRadiusPixels = getRadiusInPixels(
        newRadius,
        location.lat,
        map.current.getZoom()
      );
      map.current.setPaintProperty(
        "circle-radius",
        "circle-radius",
        newRadiusPixels
      );
    }

    updateFineLocation(fineLocation.lat, fineLocation.lng, newRadius);
  };

  return (
    <div>
      <div
        style={{
          height: editable ? "250px" : "80px",
          display: "block",
          position: "relative"
        }}
      >
        <div
          style={{ height: "100%", width: editable ? "100%" : "80px" }}
          ref={mapContainer}
          className="map-container rounded-lg shadow-2xl"
        />
      </div>

      {editable && (
        <div className="relative mb-10 mt-3">
          <label className="sr-only">Labels range</label>
          <input
            id="labels-range-input"
            type="range"
            value={circleRadius}
            onMouseUp={event =>
              handleRadiusChange(
                parseInt((event.target as HTMLInputElement).value, 10)
              )
            }
            onChange={event =>
              setCircleRadius(parseInt(event.target.value, 10))
            }
            min="100"
            max="1000"
            className="w-full shadow-lg h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          />
          <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">
            100m
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">
            1000m
          </span>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
