import React, { useEffect, useState } from "react";

// Routing Components
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Link as ScrollLink } from "react-scroll";
import { Routes } from "@util";

// UI Elements
import { Divide as Hamburger } from "hamburger-react";
import { useDarkMode } from "@hooks/dark-mode";

// Props
import { DarkThemeToggle } from "flowbite-react";
import { useUser } from "@hooks/user";

// All Links For The Header View
export const links = ["Overview", "Features", "Pricing", "Partners"];

export default function Header(props: Drivn.HeaderProps) {
  /**
   * Navigate To A Specified Link Depending On The Nav Item
   *
   * @param link - The String Element Of The Button
   * @returns The Navigation Link To Navigate To
   */
  const handleNavigate = (value: string): string => {
    let link = value.toLowerCase().replaceAll(" ", "-");
    return location.pathname === "/" ? `#${link}` : `/#${link}`;
  };

  // Is The App Using Dark Mode
  const dark = useDarkMode();

  // The Current Location
  const location = useLocation();

  // The User
  const user = useUser();

  // The Active Tab
  const [active, setActive] = useState("");

  // Listening To Make Active
  useEffect(() => {
    // Check If A Certain Page Was Scrolled To
    const getScrollTo = (int: number): boolean => {
      // The Current Heigh
      const height = window.innerHeight;

      return (
        window.scrollY > height * int &&
        window.scrollY < height * (int + 1) &&
        active !== links[int - 1]
      );
    };

    const changeNavbarColor = () => {
      switch (true) {
        case getScrollTo(1):
          setActive(links[0]);
          break;
        case getScrollTo(2):
          setActive(links[1]);
          break;
        case getScrollTo(3):
          setActive(links[2]);
          break;
        case getScrollTo(4):
          setActive(links[3]);
          break;
        default:
          setActive("");
      }
    };
    window.addEventListener("scroll", changeNavbarColor);
  });

  return (
    <section className={`${props.open ? "h-screen bg-main" : ""}`}>
      <nav
        className={`${
          props.open ? "bg-main" : "bg-bg"
        } transition-all w-screen flex items-center h-20 px-4 fixed top-0 border-b border-gray-600 z-50`}
      >
        <Link to="/" className="mr-auto ml-10">
          <img src={dark.getImage()} alt="icon" className="w-30 h-10" />
        </Link>

        <ul className="flex ml-auto items-center justify-center list-none text-gray-100 font-semibold mr-auto">
          {links.map(link => (
            <li key={link}>
              {location.pathname === "/" ? (
                <ScrollLink
                  to={link.toLowerCase().replaceAll(" ", "-")}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={`"hidden active:text-bg hidden lg:block p-2 px-3 mx-1 ${
                    active === link ? "text-bg bg-main" : "text-black"
                  } outline-2 rounded-3xl hover:text-bg hover:bg-main hover:rounded-3xl transition-all active:bg-black active:bg-text"`}
                >
                  {link}
                </ScrollLink>
              ) : (
                <HashLink
                  to={handleNavigate(link)}
                  className="hidden lg:block p-2 px-3 mx-1 text-black outline-2 rounded-3xl hover:text-bg hover:bg-main hover:rounded-3xl transition-all active:bg-black active:bg-text"
                >
                  {link}
                </HashLink>
              )}
            </li>
          ))}
        </ul>

        <div className="hidden ml-auto lg:flex [&>*]:transition-all">
          <Link
            to={
              user.signedIn === true
                ? Routes.Dashboard.path
                : Routes.Signin.path
            }
            className="mr-4 p-2 px-3 rounded-3xl text-bg bg-main hover:bg-white hover:text-main active:text-main active:bg-secBg"
          >
            {user.signedIn === true ? "Dashboard" : "Log In"}
          </Link>
          {/* <Link
            to={Routes.Pricing.path}
            className="mr-4 p-2 px-3 rounded-3xl text-black hover:text-main hover:bg-white active:bg-black"
          >
            Get Started
          </Link> */}

          <DarkThemeToggle />
        </div>

        <div className="lg:hidden">
          <Hamburger
            toggled={props.open}
            toggle={() => props.toggleOpen(!props.open)}
            color={props.open ? "#111111" : dark.dark ? "#80ABF9" : "#1673FF"}
          />
        </div>
      </nav>

      <div
        className={`${
          props.open
            ? "opacity-100 h-screen items-center mt-20 flex flex-col"
            : "pointer-events-none opacity-0"
        }`}
      >
        {links.map(link => (
          <a
            className="text-bg justify-center text-3xl my-3 hover:text-white"
            key={link}
            href={handleNavigate(link)}
            onClick={() => props.toggleOpen(false)}
          >
            {link}
          </a>
        ))}

        <Link
          className="text-bg text-3xl my-3 hover:text-white"
          to={
            user.signedIn === true ? Routes.Dashboard.path : Routes.Signin.path
          }
        >
          {user.signedIn === true ? "Dashboard" : "Log In"}
        </Link>

        {/* <a
          className="text-bg text-3xl my-3 hover:text-white"
          href={Routes.Pricing.path}
        >
          Get Started
        </a> */}
      </div>
    </section>
  );
}
