import React, { useCallback, useEffect, useRef, useState } from "react";
import { AddressAutofill } from "@mapbox/search-js-react";
// Helpers
import MapComponent from "@components/map";
import useOffices from "@hooks/offices";
import { updateTempOffice, useAppDispatch, validateOfficeAddress } from "@util";
import { hsvaToHex, getContrastingColor } from "@uiw/color-convert";
import Swatch from "@uiw/react-color-swatch";

const chartColors = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0",
  "#9966FF",
  "#FF9F40",
  "#FFCD56",
  "#4DB8FF",
  "#36A2EB",
  "#C9CB3C",
  "#FF6384",
  "#99FF99",
  "#66FF66",
  "#FF6666",
  "#3399FF",
  "#CCCCFF",
  "#99CC00",
  "#FF9966",
  "#6666FF",
  "#CC99FF",
  "#FFCC99",
  "#3366CC",
  "#99CCCC",
  "#FFCCCC",
  "#CCFFCC",
  "#CCCCCC",
  "#FF99CC",
  "#FFCC00",
  "#CCFF99",
  "#99CCFF"
];

const OfficeDrawer = () => {
  // App Dispatch
  const dispatch = useAppDispatch();
  const offices = useOffices();
  const [tempOffAdd, setTempOffAdd] = useState(offices.tempOffice?.streetNameAndNumber || '');
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [isOfficeNameValid, setIsOfficeNameValid] = useState(false);
  const addrInputRef = useRef<HTMLInputElement>(null);

  const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (addrInputRef.current) {
      addrInputRef.current.setAttribute("autoComplete", "off");
    }
    const address = event.target.value;
    setIsAddressValid(validateOfficeAddress(address));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // It is necessary to disable browser autofill for the address input manually, because the AddressAutofill component does not support it.
    if (addrInputRef.current) {
      addrInputRef.current.setAttribute("autoComplete", "off");
    }
    const address = event.target.value;
    setTempOffAdd(address);
    setIsAddressValid(validateOfficeAddress(address));
    dispatch(
      updateTempOffice({
        ...offices.tempOffice!,
        streetNameAndNumber: address
      })
    );
  };

  const handleRetrieve = useCallback(
    (res: any) => {
      if (addrInputRef.current) {
        addrInputRef.current.setAttribute("autoComplete", "off");
      }
      const feature = res.features[0];
      const featureProperties = feature.properties;
      const [lng, lat] = feature.geometry.coordinates;
      dispatch(
        updateTempOffice({
          ...offices.tempOffice!,
          streetNameAndNumber: `${featureProperties.address_line1}`,
          state: `${featureProperties.address_level1}`,
          city: `${featureProperties.address_level2}`,
          zip: `${featureProperties.postcode}`,
          lat,
          lng
        })
      );
    },
    [dispatch, offices]
  );


  const Point = (props: { color?: string; checked?: boolean }) => {
    if (!props.checked) {
      return null;
    }
    return (
      <div
        style={{
          height: 5,
          width: 5,
          borderRadius: "50%",
          backgroundColor: getContrastingColor(props.color!)
        }}
      />
    );
  };

  useEffect(() => {
    if (offices.tempOffice) {
      setTempOffAdd(offices.tempOffice.streetNameAndNumber || '');
      setIsOfficeNameValid(offices.tempOffice.name?.length > 0);
      setIsAddressValid(validateOfficeAddress(offices.tempOffice.streetNameAndNumber));
    }
  }, [offices.tempOffice]);

  return (
    <div className="flex">
      <form
        onSubmit={
          offices.tempOffice?.id === undefined
            ? offices.addOffice
            : offices.updateOffice
        }
        className={`fixed top-0 z-[100] left-0 w-full h-screen max-w-3xl p-4 overflow-y-auto transition-transform ${
          offices.tempOffice === undefined ? "-translate-x-full" : ""
        } bg-bg`}
      >
        {offices.tempOffice && (
          <>
            <h5
              id="drawer-label"
              className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400"
            >
              {offices.tempOffice.id === undefined ? "New" : "Edit"} Office
            </h5>
            <button
              type="button"
              onClick={() => dispatch(updateTempOffice(undefined))}
              className="text-gray-400 bg-transparent hover:bg-secBg hover:text-black rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
            <div>
              {offices.tempOffice.lat !== 0 && offices.tempOffice.lng !== 0 && (
                <>
                  <label
                    htmlFor="dropzone-file"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Office Location
                  </label>
                  <div className="items-center justify-center w-full">
                    <MapComponent
                      onLocationChanged={off => {
                        dispatch(
                          updateTempOffice({
                            ...offices.tempOffice!,
                            lat: off.lat,
                            lng: off.lng,
                            radius: off.radius
                          })
                        );
                      }}
                      location={offices.tempOffice}
                      editable={true}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 ">
              <div className="space-y-4 sm:col-span-2 sm:space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Office Name
                  </label>
                  <input
                    type="text"
                    className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-gray-500 dark:focus:border-gray-500"
                    placeholder="Office Name"
                    value={offices.tempOffice.name}
                    onChange={event => {
                      dispatch(
                        updateTempOffice({
                          ...offices.tempOffice!,
                          name: event.target.value
                        })
                      );
                      setIsOfficeNameValid(event.target.value.length > 0);
                    }}
                    required={true}
                  />
                  {(!isOfficeNameValid)&& (
                    <p className="text-red-500 text-sm mt-2">
                      Please write at least 1 letter.
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="addr"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Office Address
                  </label>
                  {/* @ts-expect-error Server Component */}
                  <AddressAutofill
                    accessToken={process.env.REACT_APP_MAPBOX_API_KEY!}
                    onRetrieve={handleRetrieve}
                    browserAutofillEnabled={false}
                  >
                    <input
                      type="text"
                      className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-gray-500 dark:focus:border-gray-500 autocomplete-suggestion"
                      placeholder="Office Address"
                      required={true}
                      autoComplete="off"
                      value={tempOffAdd}
                      ref={addrInputRef}
                      id="addr"
                      onFocus={onFocus}
                      onChange={onChange}
                    />
                  </AddressAutofill>
                  {(!isAddressValid) && (
                    <p className="text-red-500 text-sm mt-2">
                      Please include both street name and number.
                    </p>
                  )}
                </div>
              </div>
              <div className="space-y-4 sm:space-y-6">
                <div>
                  <label className="block mb-2 text-sm font-medium text-black">
                    Employee Count
                  </label>
                  <input
                    type="number"
                    className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Employee Count"
                    required={true}
                    value={offices.tempOffice.estimatedEmployeeCount}
                    onChange={eve => {
                      dispatch(
                        updateTempOffice({
                          ...offices.tempOffice!,
                          estimatedEmployeeCount: parseInt(eve.target.value)
                        })
                      );
                    }}
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-black">
                    Office Color (For Analytics)
                  </label>
                  <Swatch
                    colors={chartColors}
                    color={offices.tempOffice.color}
                    rectProps={{
                      children: <Point />,
                      style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }
                    }}
                    onChange={hsvColor => {
                      dispatch(
                        updateTempOffice({
                          ...offices.tempOffice!,
                          color: hsvaToHex(hsvColor)
                        })
                      );
                    }}
                  />
                  {/* <input
                    value={offices.tempOffice.color}
                    onChange={event =>
                      dispatch(
                        updateTempOffice({
                          ...offices.tempOffice!,
                          color: event.target.value
                        })
                      )
                    }
                    type="color"
                    className="bg-secBg h-[62px] border border-gray-300 cursor-pointer text-black text-sm rounded-full focus:ring-primary-500 focus:border-primary-500 block w-full dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required={true}
                  /> */}
                </div>
              </div>
            </div>
            <div className="ml-auto grid grid-cols-2 gap-4 mt-10 sm:w-1/2">
              <button
                type="button"
                onClick={() => dispatch(updateTempOffice(undefined))}
                className="inline-flex justify-center text-black items-center hover:bg-secBg transition-all focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-red-400 dark:border-gray-500 dark:focus:ring-gray-600"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 -ml-1 mr-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                Discard
              </button>
              <button
                type="submit"
                disabled={offices.loading || !isAddressValid || !isOfficeNameValid}
                className="text-main transition-all justify-center bg-secBg opacity-50 enabled:opacity-100 enabled:hover:text-bg enabled:hover:bg-main focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-800"
              >
                {offices.tempOffice.id === undefined ? "Add" : "Update"} Office
              </button>
            </div>
          </>
        )}
      </form>
      <div
        className={`fixed top-0 right-0 z-[99] h-screen transition-all ${
          offices.tempOffice === undefined ? "translate-x-full" : "w-screen"
        } bg-[rgba(0,0,0,0.5)]`}
        onClick={() => dispatch(updateTempOffice(undefined))}
      ></div>
    </div>
  );
};

export default OfficeDrawer;
