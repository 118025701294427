// Images
import { DarkIcon, LightIcon } from "@assets";
import { useThemeMode } from "flowbite-react";

// The Theme Types
export type Theme = "light" | "dark" | undefined;

/**
 * Checks If The App Is In Dark Mode
 *
 * @returns Whether Or Not The App Is In Dark Mode
 */
export const useDarkMode = () => {
  // Get The Correct Logo For The Theme
  const getImage = (): string => (theme.mode === "dark" ? DarkIcon : LightIcon);

  // The Theme
  const theme = useThemeMode();

  return {
    dark: theme.mode === "dark",
    toggle: theme.toggleMode,
    primary: theme.mode === "dark" ? "#80abf9" : "#1673ff",
    getImage
  };
};
