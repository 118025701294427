import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Id, toast } from "react-toastify";

interface LocalDepartments {
  departments: { departments: Drivn.Department[] }[];
  total: number;
  loading: boolean;
  error: import("samuel-types").Err | undefined;
  limit: number;
  tempDepartment: DepartmentForCreate | undefined;
}

// The InitialState For The Slice
const initialState: LocalDepartments = {
  departments: [{ departments: [] }],
  total: -1,
  loading: false,
  error: undefined,
  limit: 20,
  tempDepartment: undefined
};

// The Departments Redux Slice
const departmentSlice = createSlice({
  name: "Departments",
  initialState,
  reducers: {
    addDepartment: (state, { payload }: PayloadAction<Drivn.Department>) => ({
      total: state.total + 1,
      departments: updateAddDepartment(state.departments, payload, state.limit),
      loading: false,
      error: undefined,
      limit: state.limit,
      tempDepartment: undefined
    }),
    startDepartmentUpdate: state => ({ ...state, loading: true }),
    departmentError: (
      state,
      newValue: PayloadAction<[import("samuel-types").Err, Id | undefined]>
    ) => {
      if (newValue.payload[1]) {
        toast.update(newValue.payload[1], {
          render: newValue.payload[0].message,
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
      } else {
        // Toast
        toast.error(newValue.payload[0].message);
      }

      // Setting The State
      return {
        ...state,
        loading: false,
        error: newValue.payload[0]
      };
    },
    addDepartments: (
      state,
      { payload }: PayloadAction<[Drivn.DepartmentResponse, boolean]>
    ) => ({
      ...state,
      total: payload[0].total < 0 ? 0 : payload[0].total,
      departments:
        payload[1]
          ? [{ departments: payload[0].departments }]
          : [...state.departments, { departments: payload[0].departments }],
      loading: false,
      error: undefined
    }),
    removeDepartment: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        departments: state.departments.map(set => ({
          departments: set.departments.filter(department => department.id !== payload)
        })),
        total: state.total - 1,
        loading: false,
        error: undefined
      };
    },
    updateDepartment: (state, { payload }: PayloadAction<Drivn.Department>) =>
      // Returning The Updated State
      ({
        ...state,
        total: state.total,
        limit: state.limit,
        departments: state.departments.map(set => {
          if (set.departments.filter(off => off.id === payload.id)) {
            return {
              departments: set.departments.map(off => {
                if (off.id === payload.id) {
                  return payload;
                }
                return off;
              })
            };
          }

          return set;
        }),
        loading: false,
        error: undefined,
        tempDepartment: undefined
      }),
    updateTempDepartment: (
      state,
      newValue: PayloadAction<DepartmentForCreate | undefined>
    ) => ({
      ...state,
      tempDepartment: newValue.payload
    }),
    removeAllDepartments: () => initialState
  }
});

const updateAddDepartment = (
  off: { departments: Drivn.Department[] }[],
  newItem: Drivn.Department,
  limit: number
) => {
  if (off[off.length - 1].departments.length >= limit) {
    return [...off, { departments: [newItem] }];
  }
  return off.map((set, index) => {
    if (index === off.length - 1) {
      return { departments: [...set.departments, newItem] };
    }

    return set;
  });
};

// Exporting The Actions
export const {
  addDepartment,
  addDepartments,
  removeAllDepartments,
  startDepartmentUpdate,
  departmentError,
  updateDepartment,
  updateTempDepartment,
  removeDepartment
} = departmentSlice.actions;

// Exporting The Slice
export default departmentSlice;
