import React, { useState } from "react";

// Helpers
import { Form, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useSearchParams } from "react-router-dom";
import { Err, Routes } from "@util";
import * as yup from "yup";

// UI Components
import ConfigureToast from "@components/toast";
import { Spinner } from "@nextui-org/react";
import { toast } from "react-toastify";
import { Tesla } from "@assets";
import NoAuth from "./no-auth";

// Hooks
import { useDarkMode } from "@hooks/dark-mode";
import { useUser } from "@hooks/user";
import { backend } from "@hooks/api";

/**
 * The Signin Page
 */
const Login = () => {
  // Login Resolver
  const resolver = yup
    .object()
    .shape({
      email: yup.string().required("Email is required"),
      password: yup.string().required("Password is required"),
      remember: yup.boolean().required()
    })
    .required();

  // The Form Ref
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    getValues
  } = useForm<Drivn.LoginValues>({ resolver: yupResolver(resolver) });

  // The URL Search Params
  const [searchParams] = useSearchParams();

  // Should The Password Be Visible
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Dark Mode
  const dark = useDarkMode();

  // Firebase
  const firebase = useUser();

  return (
    <section
      style={{
        backgroundImage: `url(${Tesla})`,
        backgroundSize: "cover",
        backgroundColor: dark.dark
          ? "rgba(0, 0, 0, 0.6)"
          : "rgba(255, 255, 255, 0.6)",
        backgroundBlendMode: "multiply"
      }}
      className={"h-screen pt-20 lg:pt-0"}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 z-10">
        <img className="w-40 h-10 mb-4" src={dark.getImage()} alt="logo" />
        <div className="w-full bg-bg rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:border-gray-700 relative">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
              Log in to your account
            </h1>
            <Form
              encType="application/json"
              control={control}
              action={backend("/user/login")}
              onSuccess={({ response }) =>
                firebase.handleSignIn(
                  response,
                  getValues("remember"),
                  searchParams.get("redirect")
                )
              }
              onError={async ({ response }) => {
                // Formatting The Response
                const res: Err = await response?.json();
                
                // Showing The Message
                toast(res.message, { type: "error" });
              }}
              className="space-y-4 md:space-y-6"
              validateStatus={status => status === 200}
            >
              <div>
                <label className="block mb-2 text-sm text-black">
                  Your email
                </label>
                <input
                  {...register("email")}
                  type="email"
                  name="email"
                  id="email"
                  className={`${
                    errors.email
                      ? "border-[rgb(239,68,68,0.7)]"
                      : "border-gray-300 dark:border-gray-600"
                  } transition-all bg-secBg focus:outline-none border text-black sm:text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400`}
                  placeholder="name@company.com"
                  required={false}
                />
                <p className="pt-1 text-sm text-red-500 transition-all">
                  {errors.email?.message ?? ""}
                </p>
              </div>
              <div>
                <label className="block mb-2 text-sm text-black">
                  Password
                </label>
                <div
                  className={`flex border ${
                    errors.password
                      ? "border-[rgb(239,68,68,0.7)]"
                      : "border-gray-300 dark:border-gray-600"
                  } rounded-lg w-full p-2.5 bg-secBg dark:placeholder-gray-400`}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="text-black sm:text-sm w-full h-full bg-transparent border-transparent focus:outline-none"
                    required={false}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <svg
                        className="w-[19px] h-[19px] text-black"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 14"
                      >
                        <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                      </svg>
                    ) : (
                      <svg
                        className="w-[19px] h-[19px] text-black"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="m2 13.587 3.055-3.055A4.913 4.913 0 0 1 5 10a5.006 5.006 0 0 1 5-5c.178.008.356.026.532.054l1.744-1.744A8.973 8.973 0 0 0 10 3C4.612 3 0 8.336 0 10a6.49 6.49 0 0 0 2 3.587Z" />
                        <path d="m12.7 8.714 6.007-6.007a1 1 0 1 0-1.414-1.414L11.286 7.3a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.401.211.59l-6.007 6.007a1 1 0 1 0 1.414 1.414L8.714 12.7c.189.091.386.162.59.211.011 0 .021.007.033.01a2.981 2.981 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                        <path d="M17.821 6.593 14.964 9.45a4.952 4.952 0 0 1-5.514 5.514L7.665 16.75c.767.165 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                      </svg>
                    )}
                  </button>
                </div>
                <p className="pt-1 text-sm text-red-500 transition-all">
                  {errors.password?.message ?? ""}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      {...register("remember")}
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label className="text-gray-500 dark:text-gray-300">
                      Remember me
                    </label>
                  </div>
                </div>
                <Link
                  to={Routes.ForgotPassword.path}
                  className="text-sm font-normal text-main hover:underline hover:text-black"
                >
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                className="w-full text-black border border-main hover:bg-main hover:text-bg transition-all focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center dark:focus:ring-primary-800"
              >
                Log in
              </button>
              {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet?{" "}
                <a
                  href={Routes.Pricing.path}
                  className="text-main hover:underline hover:text-black"
                >
                  Sign up
                </a>
              </p> */}
            </Form>
          </div>
          <div
            className={`absolute rounded-lg bg-[rgba(255,255,255,0.68)] ${
              isSubmitting ? "opacity-100" : "opacity-0 pointer-events-none"
            } dark:bg-[rgba(0,0,0,0.68)] z-20 top-0 flex transition-all items-center justify-center w-full h-full`}
          >
            <Spinner label="Logging In..." />
          </div>
        </div>
      </div>
      <ConfigureToast />
    </section>
  );
};

// Exporting The Page
export default <NoAuth component={Login} title="Log In" />;
