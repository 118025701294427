// Helpers
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  Err,
  Routes,
  Type,
  auth,
  onAuthChange,
  useAppDispatch,
  useAppSelector,
  useNavigate
} from "@util";

// Routing
import { useLocation } from "react-router-dom";
import { Id } from "react-toastify";

// The Code To Use A User's Organization Code
export const useOrgCode = ":userOrgCode";

/**
 * The Backend URL With A Given Path Name
 *
 * @param pathName - The Function Path Name In The Backend
 * @returns A URL String Containing The Full Backend URL
 */
export const backend = (pathName: string): string =>
  `${process.env.REACT_APP_BACKEND_URL!}${pathName}`;

// Custom Typed Requests
export interface APIResponse<T> extends Response {
  json(): Promise<T | Err>;
}

export const useApi = () => {
  // The Navigator
  const navigate = useNavigate();

  // The Redux User
  const { user } = useAppSelector(state => state.user);

  // Reux Dispatcher
  const dispatch = useAppDispatch();

  // The Current Location
  const location = useLocation();

  /**
   * Makes An API Call
   *
   * @param url - The URL Path Name Of The Function Being Called
   * @param method - The Method Of The API Call (Default Is `GET`)
   * @param body - The Optional Body of The Request
   * @returns The JSON And Status Code
   */
  const requestWithJSON = <T>(
    pathName: string,
    bearer: boolean = false,
    method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
    body?: object
  ): Promise<APIRes<T>> => {
    // The Request
    const req = request<T>(pathName, bearer, method, body);
    return new Promise((res, rej) => {
      // Making The Request
      req
        .then(response => {
          // Converting To JSON
          response
            .json()
            .then(json => res([response.status, json]))
            .catch(rej);
        })
        .catch(rej);
    });
  };

  /**
   * Sets Up A Request For Calling The Backend With Full Return Data
   *
   * @param url - The URL Path Name Of The Function Being Called
   * @param method - The Method Of The API Call (Default Is `GET`)
   * @param body - The Optional Body of The Request
   */
  const request = <T>(
    pathName: string,
    bearer: boolean = false,
    method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
    body?: object
  ): Promise<APIResponse<T>> => {
    // Fetch The User Bearer Token From Local Storage
    if (bearer) {
      return new Promise(res =>
        loadLocalUserToken()
          .then(val => res(completion<T>(pathName, val, method, body)))
          // eslint-disable-next-line
          .catch(console.error)
      );
    }

    // Returning The Response With No Token
    return completion<T>(pathName, undefined, method, body);
  };

  /**
   * The Completion Function
   *
   * @param url - The URL Path Name Of The Function Being Called
   * @param method - The Method Of The API Call (Default Is `GET`)
   * @param body - The Optional Body of The Request
   */
  const completion = <T>(
    pathName: string,
    bearer?: string,
    method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
    body?: object
  ): Promise<APIResponse<T>> => {
    // The Axios Configuration
    const config: RequestInit = {
      method,
      headers: {
        Authorization: `Bearer ${bearer ?? ""}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    };

    // Replacing The Organization Codes
    if (pathName.includes(useOrgCode)) {
      if (user === undefined) {
        // eslint-disable-next-line
        console.log("Waiting at " + pathName);

        // Waiting 2 Seconds And Re-Requesting
        return new Promise(res => {
          setTimeout(() => {
            res(completion<T>(pathName, bearer, method, body));
          }, 2000);
        });
      }
      // Replace The Organization Code
      pathName = pathName.replaceAll(useOrgCode, user.organizationId);
    }

    // Debugging
    debugPrint(pathName);

    // Returning The Function Call
    return fetch(backend(pathName), config);
  };

  /**
   * Debug Printing
   *
   * @param logText - The Log Text
   */
  const debugPrint = (logText: string) => {
    // Debug Printing
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.log(`Making API Call At ${logText}`);
    }
  };

  /**
   * Load A User's Local Id Token
   *
   * @returns The User Id Token
   */
  const loadLocalUserToken = (): Promise<string> =>
    new Promise((res, rej) => {
      onAuthChange(auth, async user => {
        if (user === null) {
          navigate(`${Routes.Signin.path}?redirect=${location.pathname}`);
          rej("No Local User");
        } else {
          res(await user.getIdToken());
        }
      });
    });

  /**
   * Handle API Request Errors
   *
   * @param error - The Error Thrown
   * @param setError - An Object To Set Errors
   */
  const handleErrors = (
    error: unknown,
    toastDispatch: ActionCreatorWithPayload<[Err, Id | undefined], string>,
    id?: Id
  ) => {
    if (error instanceof Error) {
      const err = new Err({
        name: Type.VALIDATOR,
        message: error.message,
        relatedVar: "root"
      });

      dispatch(toastDispatch([err, id]));
    } else {
      const err = new Err({
        name: Type.VALIDATOR,
        message: "An error occured making this request",
        relatedVar: "root"
      });

      dispatch(toastDispatch([err, id]));
    }
  };

  return {
    request,
    requestWithJSON,
    handleErrors
  };
};
