import { useCallback, useEffect } from "react";

// Helpers
import { useApi, useOrgCode } from "@hooks/api";
import {
  useAppDispatch,
  useAppSelector,
  Err,
  analyticsError,
  startAnalyticsUpdate,
  setRecent,
  setEntity
} from "@util";

/**
 * Handle Analytics State
 */
const useAnalytics = () => {
  // The Current Analytics
  const { recentAnalytics, orgEntity, loading, error, start, end } =
    useAppSelector(({ analytics }) => analytics);

  // App Dispatch
  const dispatch = useAppDispatch();

  // The API
  const api = useApi();

  // Fetching The Analytics By Entity
  // eslint-disable-next-line
  const fetchEntity = useCallback(() => {
    api
      .requestWithJSON<Drivn.Analytics>(
        `/analytics/summary/${useOrgCode}`,
        true
      )
      .then(([, res]) => {
        // TODO -  Handle Errors Here
        if (res instanceof Err) {
          dispatch(analyticsError([res, undefined]));
          return;
        }

        // Setting The Analytics
        dispatch(setEntity(res));
      })
      .catch(err => api.handleErrors(err, analyticsError));
  }, [api, dispatch]);

  // Fetching The Analytics By Entity
  const fetchRecent = useCallback(
    (start: string, end: string) => {
      // Setting Loading
      dispatch(startAnalyticsUpdate());

      api
        .requestWithJSON<Record<string, Drivn.RecentDriveDate>>(
          `/analytics/org?start=${start}&end=${end}`,
          true
        )
        .then(([, res]) => {
          if (res instanceof Err) {
            dispatch(analyticsError([res, undefined]));
            return;
          }

          // Setting The Analytics
          dispatch(setRecent(res));
        })
        .catch(err => api.handleErrors(err, analyticsError));
    },
    [api, dispatch]
  );

  useEffect(() => {
    // If The Analytics Was Already Fetched, End Here
    if (
      loading ||
      error ||
      Object.values(recentAnalytics).length > 0 ||
      orgEntity
    ) {
      return;
    }

    // Fetch The Entity
    fetchRecent(start, end);
  }, [
    dispatch,
    error,
    loading,
    fetchRecent,
    orgEntity,
    recentAnalytics,
    start,
    end
  ]);

  return {
    entity: orgEntity,
    recentAnalytics: recentAnalytics,
    formattedStart: new Date(start).toISOString().split("T")[0],
    formattedEnd: new Date(end).toISOString().split("T")[0],
    loading,
    error,
    start,
    end,
    fetchRecent
  };
};

// Exporting The Analytics
export default useAnalytics;
