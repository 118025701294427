import React, { useState } from "react";

// Hooks
import { useUser } from "@hooks/user";

// Routing
import { useLocation } from "react-router-dom";
import { Routes } from "@routes";
import { Avatar } from "@assets";
import { Link } from "react-router-dom";

const SettingsModal = () => {
  // The User Object
  const user = useUser();

  // Expand The Billing Cell
  // A const [expandBilling, setExpandBilling] = useState(false);

  // Expand The Sidebar
  const [expandSidebar, setExpandSidebar] = useState(false);

  // The Current Location
  const location = useLocation();

  // A Settings Modal Cell
  const Cell = (props: {
    path: { path: string; absolutePath?: string };
    title: string;
    image: React.FC;
  }) => (
    <li>
      <Link
        to={props.path.absolutePath ?? props.path.path}
        onClick={() => setExpandSidebar(false)}
        className={`${
          location.pathname === props.path.absolutePath
            ? "text-bg bg-main hover:bg-white hover:text-black"
            : "text-black hover:bg-bg"
        } flex items-center p-2 text-base transition-all font-normal rounded-lg group`}
      >
        <svg
          aria-hidden="true"
          className={`${
            location.pathname === props.path.absolutePath
              ? "text-bg group-hover:text-black"
              : "text-gray-400 group-hover:text-main"
          } w-5 h-5 transition duration-75`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <props.image />
        </svg>
        <span className="ml-3">{props.title}</span>
      </Link>
    </li>
  );

  return (
    <div>
      <button
        data-drawer-target="sidebar-user"
        data-drawer-toggle="sidebar-user"
        aria-controls="sidebar-user"
        type="button"
        onClick={() => setExpandSidebar(!expandSidebar)}
        className="inline-flex items-center mt-[120px] p-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-bg focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="sidebar-user"
        className={`fixed top-0 left-0 z-40 w-64 h-full mt-[110px] mb-[242px] transition-transform ${
          !expandSidebar && "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="overflow-y-auto py-4 px-3 h-full border-r border-gray-200 bg-secBg dark:border-gray-700">
          <div className="text-center text-gray-500 dark:text-gray-400">
            <img
              className="mx-auto mb-4 w-20 h-20 rounded-full"
              src={user.user?.image ?? Avatar}
              alt="avatar"
            />
            <h3 className="text-xl font-bold tracking-tight text-black">
              <a href="/">{`${user.user?.firstName ?? ""} ${
                user.user?.lastName ?? ""
              }`}</a>
            </h3>
            <p className="font-light text-gray-500 dark:text-gray-400">
              {user.email ?? ""}
            </p>
            <button
              onClick={user.signOut}
              className="inline-flex hover:bg-red-500 transition-all items-center justify-center w-full py-2.5 px-5 my-5 text-sm font-medium text-black focus:outline-none rounded-lg border border-gray-200 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 bg-secBg dark:border-gray-600"
            >
              <div className="rotate-180 mr-2">
                <svg
                  aria-hidden="true"
                  className="mr-1 w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  ></path>
                </svg>
              </div>
              Logout
            </button>
          </div>
          <ul className="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
            <Cell
              path={Routes.Dashboard.Settings}
              title="General"
              image={() => (
                <path
                  fillRule="evenodd"
                  d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              )}
            />
            <Cell
              path={Routes.Dashboard.Settings.Organization}
              title="Organization Info"
              image={() => (
                <path d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z" />
              )}
            />
            <Cell
              path={Routes.Contact}
              title="Contact Support"
              image={() => (
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              )}
            />
            {/* <li>
              <button
                type="button"
                className="flex items-center p-2 w-full text-base font-normal text-black rounded-lg transition duration-75 group hover:bg-bg"
                onClick={() => setExpandBilling(!expandBilling)}
              >
                <svg
                  className="w-6 h-6 text-main"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 14"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM2 12V6h16v6H2Z" />
                  <path d="M6 8H4a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2Zm8 0H9a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2Z" />
                </svg>
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Billing
                </span>
                <svg
                  aria-hidden="true"
                  className={`w-6 h-6 transition-all ${
                    expandBilling && "rotate-180"
                  }`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-tasks"
                className={`${expandBilling ? "" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to={Routes.Dashboard.Settings.Billing.PaymentInfo.path}
                    className="flex items-center p-2 pl-11 w-full text-base font-normal text-black rounded-lg transition duration-75 group hover:bg-bg"
                  >
                    Payment Info
                  </Link>
                </li>
                <li>
                  <a
                    href="/"
                    className="flex items-center p-2 pl-11 w-full text-base font-normal text-black rounded-lg transition duration-75 group hover:bg-bg"
                  >
                    Invoices
                  </a>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default SettingsModal;
