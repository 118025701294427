import React from "react";

const Features = () => (
  <section id="features" className="bg-secBg lg:h-screen flex items-center justify-center border-b border-gray-600">
    <div className="items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid xl:grid-cols-3 lg:gap-8 xl:gap-24 sm:py-16 lg:px-6">
      <div className="mb-8 lg:mb-0">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-black">
          Designed for sustainability teams like yours
        </h2>
        <p className="mb-4 text-gray-500 sm:text-xl dark:text-gray-400">
          At Drivn we focus on leveraging advanced technologies and creativity
          to unlock emissions data never seen before
        </p>
      </div>
      <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
        <div>
          <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-main">
            <svg
              className="w-5 h-5 text-bg lg:w-6 lg:h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <h3 className="mb-2 text-xl font-bold">High Quality Data</h3>
          <p className="text-gray-500 dark:text-gray-400">
            Eliminate human error and ensure high-quality data with our fully
            automatic tracking.
          </p>
        </div>
        <div>
          <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-main">
            <svg
              className="w-5 h-5 lg:w-6 lg:h-6 text-bg"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
              <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
            </svg>
          </div>
          <h3 className="mb-2 text-xl font-bold">100% Automated</h3>
          <p className="text-gray-500 dark:text-gray-400">
            Our mobile app leverages machine learning and adaptive geofencing to
            automatically identify and calculate emissions from work-related
            commutes.
          </p>
        </div>
        <div>
          <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-main">
            <svg
              className="w-5 h-5 lg:w-6 lg:h-6 text-bg"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path>
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <h3 className="mb-2 text-xl font-bold ">Valuable Insights</h3>
          <p className="text-gray-500 dark:text-gray-400">
            Educate your employees in their commuting habits and related
            emissions.
          </p>
        </div>
        <div>
          <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-main lg:h-12 lg:w-12">
            <svg
              className="w-5 h-5 text-bg lg:w-6 lg:h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
            </svg>
          </div>
          <h3 className="mb-2 text-xl font-bold ">Compliance</h3>
          <p className="text-gray-500 dark:text-gray-400">
            Our web-based admin panel aggregates real-time data, helping you
            meet CSRD and ESG requirements effortlessly.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Features;
