import React from "react";
import Footer from "@components/navigation/footer";

const PrivacyPolicy = () => (
  <>
    <main>
      <div className="terms-container mb-7">
        <h1>PRIVACY POLICY</h1>
        <p className="update-date">Last updated October 13, 2023</p>

        <section className="legal-terms section">
          <h2>1. INTRODUCTION</h2>
          <p>
            {`This privacy policy (the "Privacy Policy") pertains to all
            processing of Personal Data conducted by Drivn ApS, a company
            registered in Denmark, (also referred to as "Drivn"). "Personal
            data" encompasses any information that may be directly or indirectly
            associated with a living individual. The privacy policy is designed
            to assure you that Drivn handles your Personal Data in accordance
            with the relevant privacy legislation. The following details how
            Drivn processes Personal Data, your rights, and how to contact Drivn
            if you have any questions or wish to exercise your rights. Should
            there be any changes to the Privacy Policy, we will notify you via
            email and an updated version will be made available in the
            application and on Drivn's website{" "}`}
            <a href="https://drivn3.com">https://drivn3.com</a>.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>2. PERSONAL DATA PROCESSING, PURPOSE, AND LEGAL BASIS</h2>
          <p>
            Drivn collects and processes Personal Data solely to the extent
            needed to deliver, promote, execute, and assess Drivn's services.
            Creating a user in our system enables you to use the app and enables
            us to communicate with you about the app.
          </p>
          <p>This involves the following objectives:</p>
          <ul>
            <li>Creation of user and collection of master data.</li>
            <li>
              Distribution of notifications from your organization’s
              administration, including targeting this communication with you.
            </li>
          </ul>

          <p>We process the following personal data about you:</p>
          <ul>
            <li>
              General personal data:
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Telephone number</li>
                <li>Microsoft account in case of login via Microsoft</li>
                <li>Google account in case of login via Google</li>
                <li>
                  Master data about your submitted vehicle, including its brand,
                  year, model, environmental impact and VIN, however, only in
                  case you submit the license plate of your car through our
                  license plate search.
                </li>
                <li>
                  GPS coordinates and matching timestamps, if your consent is
                  given. For a more detailed description see section 8.
                </li>
              </ul>
            </li>
            <li>Sensitive data: None</li>
            <li>Personal data about criminal offenses and sentences: None</li>
          </ul>

          <p>We collect information from the following sources:</p>
          <ul>
            <li>Directly from you</li>
            <li>The Central Register of Motor Vehicles</li>
          </ul>

          <p>
            We process the personal data for this purpose on the following
            basis:
          </p>
          <ul>
            <li>
              Article 6(1)(a) of the General Data Protection Regulation (GDPR)
              (consent)
            </li>
            <li>
              Article 6(1)(f) of the GDPR (necessary for the purposes of the
              legitimate interests pursued by us). Our legitimate interests are
              to be able to provide the services requested by you, to target and
              distribute for communication purposes, and to further develop the
              services as regards the user (website and apps) as well as the
              system (functionality, etc.).
            </li>
          </ul>

          <p>
            We shall process the personal data as long as it is deemed necessary
            for the purposes stated.
          </p>
          <p>
            This means that we shall process the personal data listed in
            accordance with the above description. We shall process your master
            data as long as you have a user account with us. Master data and
            technical information about the submitted vehicle is stored as long
            as the vehicle is supported by the NummerpladeAPI service. If you
            remove a vehicle from your user account, historic data will be kept
            stored in our servers. The reason for this is that your historic
            commutes and travels might still be relevant to you and your
            organization even though the vehicle has been removed from the user
            account. If you close your account, all information about you will
            be anonymized within 7 days.
          </p>

          <p>We share the personal data with the following parties:</p>
          <ul>
            <li>Your organization’s administration</li>
            <li>Our sub-processors, e.g. for the purpose of support</li>
            <li>
              Public authorities, for the purpose of complying with legal
              obligations and/or safeguarding public interests, which supersede
              the interests of secrecy.
            </li>
          </ul>
        </section>
        <section className="legal-terms section">
          <h2>3. SENSITIVE PERSONAL DATA</h2>
          <p>
            Sensitive Personal Data pertains to information that reveals racial
            or ethnic origin, political beliefs, religious or philosophical
            convictions, or trade union membership, as well as Personal Data
            relating to health or sexual orientation. Generally, Drivn does not
            process any sensitive Personal Data about the User.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>4. INFORMATION TO OTHERS</h2>
          <p>
            Drivn will not share your Personal Data with any third party outside
            the scope of this Privacy Policy unless legally mandated or
            connected with a legal, administrative, or collection procedure
            involving you and Drivn.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>5. STORAGE OF PERSONAL DATA</h2>
          <p>
            Personal data processed by Drivn are stored on an external server
            situated within the EU/EEA. The data is retained only as long as
            necessary for processing purposes. Drivn will not retain the data
            beyond what is legally required and will dispose of Personal Data in
            accordance with prevailing laws.
          </p>
          <p>Personal data will be deleted in the case of the following:</p>
          <ul>
            <li>In agreement with your employer.</li>
            <li>
              In the event that the collaboration between your employer and
              Drivn is terminated.
            </li>
          </ul>
        </section>

        <section className="legal-terms section">
          <h2>6. TRANSFERS TO THIRD COUNTRIES</h2>
          <p>
            Drivn generally does not transfer Personal Data to third countries
            (i.e., countries outside the EU/EEA). Should such a transfer become
            necessary in the future, Drivn will do so only if it complies with
            the legal requirements in GDPR chapter 5.
          </p>
        </section>
        <section className="legal-terms section">
          <h2>7. EXTERNAL LINKS</h2>
          <p>
            This privacy policy applies only to Personal Data processed by Drivn
            through its services and digital platforms. The Drivn website may
            contain links to external sites that Drivn does not oversee. Drivn
            assumes no responsibility for any Personal Data handling on such
            websites, which are not governed by this Privacy Policy.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>8. YOUR DATA</h2>
          <p>
            <strong>What data do we collect?</strong>
          </p>
          <p>Drivn collects the following data:</p>
          <ul>
            <li>
              Personal identification information
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Telephone number</li>
              </ul>
            </li>
            <li>
              From the use of Global Positioning System (GPS)
              <ul>
                <li>Coordinates, more specifically latitude and longitude</li>
                <li>Timestamps matching the collected coordinates</li>
                <li>The distance in kilometers of your travels and commutes</li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>How do we collect your data?</strong>
          </p>
          <p>
            We only collect GPS coordinates if you have given the application
            access to your smartphone's location services by choosing the “allow
            always” setting. You provide most of the data directly to Drivn. We
            collect and process data when you engage with the Drivn mobile
            application by:
          </p>
          <ul>
            <li>Registering an account</li>
            <li>Enabling GPS tracking</li>
          </ul>

          <p>
            We collect your GPS coordinates, more specifically your latitude and
            longitude, every 5 to 10 minutes. This will occur in the background
            even if the application is fully terminated.
          </p>

          <p>
            If, and only if, our program detects your travel as work-related, we
            will store the collected GPS coordinates. Otherwise, all GPS data,
            including coordinates, will be deleted within 24 hours.
          </p>

          <p>
            You can at any time turn off Drivn’s allowance of GPS tracking in
            the settings page of the application. Please note that turning GPS
            tracking off will result in most functions to stop working.
            Therefore, it will affect your aggregated data and the precision of
            your organization’s emissions data.
          </p>

          <p>
            <strong>
              The following are data we do NOT collect that you might think we
              did:
            </strong>
          </p>
          <ul>
            <li>The specific routes of each travel and commute</li>
            <li>Your home address</li>
            <li>Information on your passengers, if any</li>
            <li>Behavioral data, such as driving speed and habits</li>
            <li>
              Motion sensor data, such as from gyroscope and accelerometer
            </li>
            <li>
              Interaction with in-car systems, if any (e.g., Apple CarPlay,
              Android Auto)
            </li>
          </ul>

          <p>
            <strong>How will we use your data?</strong>
          </p>
          <p>Drivn uses your data to:</p>
          <ul>
            <li>Determine whether a travel is work-related or private.</li>
            <li>
              Compile and deliver aggregated CO2 and emissions-related
              statistics to your employer, using data from all employees
              utilizing Drivn.
            </li>
            <li>
              Display your individual statistics related to your business travel
              and daily commute in the mobile application.
            </li>
            <li>
              Provide GPS coordinates and timestamps to our machine learning
              model to automatically detect what transportation type is being
              employed when a user is traveling.
            </li>
          </ul>
        </section>
        <section className="legal-terms section">
          <h2>9. DATA SHARING WITH YOUR EMPLOYER</h2>
          <p>
            <strong>Aggregated Data:</strong>
          </p>
          <p>
            We may create statistical, aggregated data relating to our users and
            the services, for analytical purposes. Aggregated data includes data
            derived from your personal information and obtained by Drivn during
            your use of our services.
          </p>

          <p>
            <strong>Anonymized Data:</strong>
          </p>
          <p>
            We may anonymize your personal information so that you cannot be
            individually identified, and provide that anonymized information to
            your employer. You can at any time choose to anonymize your data in
            the settings page of the application.
          </p>

          <p>
            <strong>Employer Reporting:</strong>
          </p>
          <p>
            We provide employers with reports containing organizational,
            department-level, office-level, and employee-level insights and
            trends. You can anonymize your individual aggregated data through
            the settings page. Please note that having your aggregated data
            anonymized will result in you not being able to participate in
            competitions, leaderboards, and other social activities that might
            be set up by your employer.
          </p>

          <p>
            <strong>Consent:</strong>
          </p>
          <p>
            Unless we have your explicit consent, we will not provide your
            employer with data that could be used to identify you as an
            individual. This practice aligns with our core commitment to user
            privacy and compliance with relevant privacy laws and regulations.
            Even if you have created an account in the Drivn app and given your
            consent to collection of GPS data, you can always choose to
            anonymize your data in the settings page of the application.
          </p>

          <p>
            <strong>Your Rights:</strong>
          </p>
          <p>
            You may have the right to request more information about the data we
            share with your employer or other parties, and where applicable, you
            may request restrictions on such sharing. Please refer to the
            sections above detailing your rights and how to contact us.
          </p>

          <p>
            Your trust is of paramount importance to us, and we strive to
            maintain robust privacy controls in line with our commitment to your
            privacy.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>RIGHT TO REQUEST INFORMATION</h2>
          <p>
            You have the right to request information about the personal data we
            hold on you at any time. Contact us, and we will provide you with
            your personal data via email.
          </p>
        </section>
        <section className="legal-terms section">
          <h2>RIGHT TO CORRECTION</h2>
          <p>
            You have the right to request the correction of inaccurate personal
            information we hold about you. You can manage some of this
            information directly in your account settings. For other
            corrections, please contact us.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>THE RIGHT TO BE FORGOTTEN</h2>
          <p>
            In some cases, you have the right to request that we delete your
            personal data. This may apply if the data is no longer necessary for
            the purposes for which it was collected or if you withdraw consent
            on which the processing is based. There may be legal obligations
            that prevent us from immediately deleting parts of the data. These
            obligations come from accounting and tax laws, banking and
            anti-money laundering laws, etc.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>RIGHT TO LIMITATION OF PROCESSING</h2>
          <p>
            You have the right to request that Drivn restrict the processing of
            your personal data under certain circumstances, such as if you
            believe the data is incorrect or the processing is unlawful.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>REDUCTION OF DATA</h2>
          <p>
            We process and store only the minimum personal data required to
            fulfill our contractual obligations and comply with legal
            requirements. If we no longer need your personal data for the
            performance of our obligations or if the data is no longer required
            by law, we will erase it.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>RIGHT TO OBJECT</h2>
          <p>
            You have the right to object to processing based on legitimate
            interests. The processing will cease unless Drivn demonstrates
            compelling legitimate grounds for the continued processing.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>THE RIGHT TO DATA PORTABILITY</h2>
          <p>
            Whenever Drivn processes your personal data by automated means based
            on your consent or an agreement, you have the right to obtain a copy
            of your data transferred to you or another party in a structured,
            commonly used, and machine-readable format.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>THE RIGHT TO COMPLAINT</h2>
          <p>
            C.f. GDPR article 14(2)(d): if you have any concerns or complaints
            regarding our handling of your personal data, you have the right to
            file a complaint with Datatilsynet (The Danish Data Protection
            Agency). You can find the contact details of Datatilsynet{" "}
            <a href="https://datatilsynet.dk/">here</a>.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>SECURITY</h2>
          <p>
            We employ organizational, technical, and administrative measures to
            protect your personal information. However, no online or email
            transmission is ever fully secure or error-free. Please keep this in
            mind, especially when disclosing any personal or sensitive
            information via email or online channels.
          </p>
        </section>

        <section className="legal-terms section">
          <h2>CONTACT DETAILS</h2>
          <p>
            Should you wish to exercise your rights as detailed above, please
            make your request in writing by email to info@drivn3.com. To ensure
            that Drivn does not disclose your Personal Data to unauthorized
            individuals, please include a valid ID document with your request,
            such as a passport or driver's license. For any questions about this
            policy or Drivn's processing of personal data, you can contact Drivn
            by email, also at info@drivn3.com.
          </p>
        </section>

        <section className="legal-terms section">
          <p>Drivn ApS</p>
          <p>Bredgade 36C, 2. Sal</p>
          <p>1260 København K, Danmark</p>
          <p>CVR: 43378880</p>
        </section>
      </div>
    </main>
    <Footer />
  </>
);

export default PrivacyPolicy;
